import React, { useEffect, useRef, useState, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from 'three'
import Random from 'canvas-sketch-util/random';
import { useGLTF } from "@react-three/drei";

export function Model3D() {
  const {nodes, material} = useGLTF("./models/corynth/corynth-v1.glb")



  const [modelPositions, setModelPositions] = useState(null)
  const [modelGeoAmount, setModelGeoAmount] = useState(null)
  const ref = useRef(null)

  const allGeometries = useMemo(() => {
    let tmpAllGeo = []
    nodes.Scene['children'].map( (item, index) => {
      let tmpGeo = item.geometry.attributes.position.array
      tmpAllGeo.push(tmpGeo)
    })
    let simplerGeo = tmpAllGeo.map(a => [...a]).flat();
    return simplerGeo

  }, [])
  const positionsLength = allGeometries.length/3
    // Generate model positions, speed factors and timings
  const particles = useMemo(() => {
        let temp = [];
        for (let i = 0; i < positionsLength; i+=2) {
          const time = Random.range(0, 100);
          const factor = Random.range(0, 10);
          const speed = Random.range(0.01, 0.015) / 2;

          let index = i * 3

          const x = allGeometries[index]
          const y = allGeometries[index + 1]
          const z = allGeometries[index + 2]
    
          temp.push({ time, factor, speed, x, y, z });
        }
        return temp;
  }, []);

  const dummy = useMemo(() => new THREE.Object3D(), []);
  useEffect(() => {
    particles.forEach((particle, index) => {
      let { factor, speed, x, y, z } = particle;
      dummy.position.set(x, y, z)
      dummy.updateMatrix();
      ref.current.setMatrixAt(index, dummy.matrix)
    });
    ref.current.instanceMatrix.needsUpdate = true;
  }, []);


  return (<>
  <instancedMesh position={[0, 2, 0]} rotation={[-Math.PI/2,0, 0]} scale={0.2} ref={ref} args={[undefined, undefined, positionsLength]} castShadow receiveShadow>
            <sphereBufferGeometry args={[0.1, 5,4]} />
            <meshPhongMaterial />
        </instancedMesh>
  </>)
}

export default Model3D