import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import {
  EffectComposer,
  Bloom,
} from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";
import {
  CubeCamera,
  Environment,
  OrbitControls,
  PerspectiveCamera,
  Html,
  useProgress
} from "@react-three/drei";
import "./style.css";
import { Boxes } from "./Boxes";
import { FloatingGrid } from "./FloatingGrid";
import { Rings } from "./Rings";
import Model3D from "./Model3D";

function CarShow() {
  return (
    <>
      <OrbitControls 
        target={[0, 0.35, 0]}
      />
      <PerspectiveCamera makeDefault fov={50} position={[0, 2, -7]} />
      <color args={[0, 0, 0]} attach="background" />
      <CubeCamera resolution={256} frames={Infinity}>
        {(texture) => (
          <>
            <Environment map={texture} />
            <Model3D />
          </>
        )}
      </CubeCamera>
      <spotLight
        color={'white'}
        intensity={1}
        angle={0.5}
        penumbra={0.5}
        position={[0, 7, 0]}
        castShadow
        shadow-bias={-0.0001}
      />
      <FloatingGrid />
      <Boxes />
      <Rings />
      <EffectComposer>
        <Bloom
          blendFunction={BlendFunction.ADD}
          intensity={0.1} // The bloom intensity.
          width={300} // render width
          height={300} // render height
          kernelSize={5} // blur kernel size
          luminanceThreshold={0.15} // luminance threshold. Raise this value to mask out darker elements in the scene.
          luminanceSmoothing={0.025} // smoothness of the luminance threshold. Range is [0, 1]
        /> 
      </EffectComposer>
    </>
  );
}

function Loader() {
  const { progress } = useProgress()
  let progressF = Math.floor(progress)
  return <Html center>{progressF} % loaded</Html>
} 

function App() {
  return (
      <Canvas shadows gl={{physicallyCorrectLights: false, antialias: false}}>
        <Suspense fallback={<Loader />}>
          <CarShow />
        </Suspense>
      </Canvas>
  );
}

export default App;
